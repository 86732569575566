import {
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";

import useCancelScheduledCampaign from "../apis/hooks/use-cancel-scheduled-campaign";
import useGetBroadcasts from "../apis/hooks/use-get-broadcasts";
import Spinner from "../components/Spinner";
import CustomBreadcrumbs from "../components/custom-breadcrumbs";
import { useSnackbar } from "../components/snackbar";
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from "../components/table";
import PageContext from "../context/pageContext";
import { useRouter } from "../hooks";
import BroadcastTableRow from "../sections/broadcast/broadcast-table-row";
import { IBroadcast } from "../types/broadcast.type";
import { IPageContext } from "../types/page.type";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "broadcastName", label: "Name" },
  { id: "broadcastStatus", label: "Status" },
  { id: "broadcastTime", label: "Delivery Date" },
  { id: "broadcastRecipientCount", label: "Recipients" },
  { id: "broadcastReadCount24Hour", label: "Read (24hours)" },
  { id: "broadcastReadCount7Day", label: "Read (7days)" },
  { id: "broadcastClickCount", label: "Unique Clicks" },
  { id: "broadcastAttributionRevenue", label: "Revenue" },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function CampaignListView() {
  const { pageId } = useContext(PageContext) as IPageContext;
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const cancelScheduledCampaign = useCancelScheduledCampaign();

  const table = useTable({ defaultOrderBy: "broadcastTime" });

  const [tableData, setTableData] = useState<IBroadcast[]>([]);

  let page = table.page + 1;
  let PAGE_SIZE = table.rowsPerPage;

  const { broadcastListIsLoading, data, count } = useGetBroadcasts({
    pageId,
    page,
    PAGE_SIZE,
    isFlow: false,
  });

  useEffect(() => {
    if (!broadcastListIsLoading && data?.broadcasts) {
      setTableData(data.broadcasts);
    }
  }, [broadcastListIsLoading, data]);

  if (broadcastListIsLoading) {
    return <Spinner />;
  }

  const handleCancelRow = (broadcastId: string) => {
    cancelScheduledCampaign.mutate(broadcastId, { onSuccess });
  };

  const handleViewRow = async (broadcastId: string) => {
    const url = `/app/campaign/${broadcastId}`;
    router.push(url);
  };

  const handleEditRow = async (broadcastId: string) => {
    const url = `/app/campaign/${broadcastId}/edit`;
    router.push(url);
  };

  const onSuccess = () => {
    enqueueSnackbar("Broadcast Canceled");
  };

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          spacing={2}
          direction="column"
          alignItems="left"
          sx={{
            mb: { xs: 3, md: 5 },
            padding: "1rem",
          }}
        >
          <CustomBreadcrumbs
            heading="Campaigns"
            links={[
              {
                name: "Home",
                href: "/",
              },
              {
                name: "Campaigns",
              },
            ]}
          />

          <Card>
            <TableContainer
              sx={{ position: "relative" }}
              style={{ marginBottom: "0px" }}
            >
              <Table size={"small"} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                />

                <TableBody style={{ marginBottom: "0px" }}>
                  {tableData.length > 0 ? (
                    tableData.map((row) => (
                      <BroadcastTableRow
                        key={row.broadcastId}
                        row={row}
                        onViewRow={() => handleViewRow(row.broadcastId)}
                        onCancelRow={() => handleCancelRow(row.broadcastId)}
                        onEditRow={() => handleEditRow(row.broadcastId)}
                      />
                    ))
                  ) : (
                    <TableNoData notFound={true} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationCustom
              count={count || 0}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={(event, newPage) => {
                table.onChangePage(event, newPage);
              }}
              style={{ marginTop: "0px" }}
            />
          </Card>
        </Stack>
      </Container>
    </>
  );
}
