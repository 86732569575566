import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { Edge, Node, ReactFlowProvider } from "@xyflow/react";
import "@xyflow/react/dist/style.css";

import Iconify from "../components/iconify";
import { ActionNodeType } from "../components/react-flow/types";
import { RouterLink } from "../helpers/router-link";
import FlowCanvas from "../sections/flow/flow-canvas";

// ----------------------------------------------------------------------

// Default
const defaultNodes: Node[] = [
  {
    id: "1",
    type: ActionNodeType.TRIGGER,
    data: { label: "Message 1", title: "FAcebook Message" },
    position: { x: 0, y: 0 },
  },
  {
    id: "2",
    type: "end",
    data: {},
    position: { x: 0, y: 0 },
  },
];

const defaultEdges: Edge[] = [
  { id: "e1-2", type: "action", source: "1", target: "2" },
];

const currentFlow = { nodes: defaultNodes, edges: defaultEdges };

// ----------------------------------------------------------------------

export default function Flow() {
  const handleToggle = () => {};
  return (
    <ReactFlowProvider>
      <Container maxWidth={false}>
        <Stack
          spacing={1}
          direction="column"
          alignItems="left"
          sx={{
            mb: { xs: 3, md: 5 },
            padding: "1rem",
          }}
        >
          <Stack spacing={1.5} direction="row">
            <Button
              component={RouterLink}
              href="/app/flow"
              startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
            >
              Back
            </Button>

            <Box sx={{ flexGrow: 1 }} />

            <Button onClick={handleToggle} variant="contained" color="primary">
              Toggle
            </Button>
          </Stack>
          <FlowCanvas currentFlow={currentFlow} />
        </Stack>
      </Container>
    </ReactFlowProvider>
  );
}
