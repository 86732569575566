import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, CardHeader, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import useUpdateAttributionWindow from "../../apis/hooks/use-update-attribution-window";
import FormProvider, { RHFTextField } from "../../components/hook-form";
import Iconify from "../../components/iconify";
import { useSnackbar } from "../../components/snackbar";
import PageContext from "../../context/pageContext";
import { loginWithFacebook, savePage } from "../../helpers/fbServices";
import { useAuthContext } from "../../hooks";
import { IPageContext } from "../../types/page.type";

// ----------------------------------------------------------------------

export default function SettingsAccount() {
  const { connectPage } = useAuthContext();
  const { fbPage } = useContext(PageContext) as IPageContext;

  const updateAttributionWindow = useUpdateAttributionWindow();

  const { enqueueSnackbar } = useSnackbar();

  const performConnect = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    try {
      const userAuthResponse = await loginWithFacebook();
      const userId = localStorage.getItem("userId") || "";

      const { connectedPages } = await savePage({ userAuthResponse, userId });

      connectPage(connectedPages);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  };

  const attributionSchema = Yup.object().shape({
    attributionWindow: Yup.number(),
  });

  const defaultValues = {
    attributionWindow: fbPage.attributionWindow,
  };

  const methods = useForm({
    resolver: yupResolver(attributionSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const saveAttribution = async (data: any) => {
    updateAttributionWindow.mutate(
      {
        pageId: fbPage.pageId,
        data,
      },
      { onSuccess }
    );
  };

  useEffect(() => {
    if (fbPage) {
      reset(defaultValues);
    }
  }, [reset, fbPage]);

  const onSuccess = () => {
    enqueueSnackbar("Your attribution window has been saved", {
      variant: "success",
    });
    reset();
  };

  const renderConnect = (
    <Card>
      <CardHeader title="Connected Pages" />
      <Stack spacing={2.5} sx={{ p: 3 }}>
        <Button
          size="small"
          startIcon={<Iconify icon={"bi:facebook"} />}
          onClick={performConnect}
          sx={(theme) => ({
            p: 2.5,
            border: 1,
            backgroundColor: "#316FF6",
            color: theme.palette.grey[100],
            width: "200px",
            "&:hover": {
              backgroundColor: "#2758c4",
            },
          })}
        >
          Manage Pages
        </Button>
      </Stack>
    </Card>
  );

  const renderAttribution = (
    <FormProvider methods={methods} onSubmit={handleSubmit(saveAttribution)}>
      <Card>
        <CardHeader
          title="Attribution Window"
          action={
            <Button
              size="medium"
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isDirty || updateAttributionWindow.isPending}
            >
              Save
            </Button>
          }
        />
        {/* <Stack spacing={2.5} sx={{ p: 3 }}>
          <Stack
            spacing={{ xs: 1, sm: 5 }}
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            justifyContent={"flex-start"}
          >
            <Typography variant="subtitle2">Clicked Message</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <RHFTextField
                name="attributionWindow"
                type="number"
                sx={{ maxWidth: "60px" }}
                inputProps={{ style: { textAlign: "center" } }}
              />
              <Typography variant="body2">Days</Typography>
            </Stack>
          </Stack>
        </Stack> */}
        <Stack spacing={2.5} sx={{ p: 3 }}>
          <Typography variant="body1">
            Determine the time frame leading up to a conversion to give credit
            to.
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid xs={12} md={2} display="flex" alignItems="center">
              <Typography variant="subtitle2">Clicked Message</Typography>
            </Grid>
            <Grid xs={12} md={2} display="flex" alignItems="center">
              <RHFTextField
                name="attributionWindow"
                type="number"
                disabled={updateAttributionWindow.isPending}
                sx={{ maxWidth: "60px", mr: 2 }}
                inputProps={{ style: { textAlign: "center" } }}
              />
              <Typography variant="body2">Days</Typography>
            </Grid>
          </Grid>
        </Stack>
      </Card>
    </FormProvider>
  );

  return (
    <>
      {renderConnect}
      {renderAttribution}
    </>
  );
}
