import {
  Button,
  IconButton,
  Link,
  MenuItem,
  TableCell,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";

import { ConfirmDialog } from "../../components/custom-dialog";
import CustomPopover, { usePopover } from "../../components/custom-popover";
import Iconify from "../../components/iconify";
import Label from "../../components/label";
import { RouterLink } from "../../helpers/router-link";
import { useBoolean } from "../../hooks";
import { BroadcastStatus, IBroadcast } from "../../types/broadcast.type";

// ----------------------------------------------------------------------

type Props = {
  row: IBroadcast;
  onViewRow: VoidFunction;
  onCancelRow: VoidFunction;
  onEditRow: VoidFunction;
};

type StatusProp = {
  statusText: string;
  statusColor:
    | "info"
    | "success"
    | "error"
    | "warning"
    | "default"
    | "primary"
    | "secondary";
};

export default function BroadcastTableRow({
  row,
  onViewRow,
  onCancelRow,
  onEditRow,
}: Props) {
  const {
    broadcastId,
    broadcastStatus,
    broadcastName,
    broadcastTime,
    broadcastRecipientCount,
    broadcastReadCount24Hour,
    broadcastReadCount7Day,
    broadcastClickCount,
    broadcastAttributionRevenue,
  } = row;

  const confirm = useBoolean();

  const popover = usePopover();

  const [isHovered, setIsHovered] = useState(false);
  const [status, setStatus] = useState({} as StatusProp);

  useEffect(() => {
    statusConvertFull(broadcastStatus);
  }, [broadcastStatus]);

  const statusConvertFull = (status: String) => {
    switch (status) {
      case "0":
        setStatus({
          statusText: "Draft",
          statusColor: "secondary",
        });
        break;
      case "1":
        setStatus({
          statusText: "Scheduled",
          statusColor: "info",
        });
        break;
      case "2":
        setStatus({
          statusText: "Sending",
          statusColor: "success",
        });
        break;
      case "3":
        setStatus({
          statusText: "Sent",
          statusColor: "success",
        });
        break;
      case "4":
        setStatus({
          statusText: "Canceled",
          statusColor: "error",
        });
        break;
      case "5":
        setStatus({
          statusText: "Ongoing",
          statusColor: "success",
        });
        break;
      default:
        setStatus({
          statusText: "Unknown",
          statusColor: "secondary",
        });
        break;
    }
  };

  const broadcastReadCount24HourPercentage =
    broadcastReadCount24Hour === 0
      ? "-"
      : (broadcastReadCount24Hour / broadcastRecipientCount).toLocaleString(
          "en",
          { style: "percent" }
        );
  const broadcastReadCount7DayPercentage =
    broadcastReadCount7Day === 0
      ? "-"
      : (broadcastReadCount7Day / broadcastRecipientCount).toLocaleString(
          "en",
          { style: "percent" }
        );
  const broadcastClickCountPercentage =
    broadcastClickCount === 0
      ? "-"
      : (broadcastClickCount / broadcastRecipientCount).toLocaleString("en", {
          style: "percent",
        });

  const display24Hour = isHovered
    ? broadcastReadCount24Hour
    : broadcastReadCount24HourPercentage;
  const display7Day = isHovered
    ? broadcastReadCount7Day
    : broadcastReadCount7DayPercentage;
  const displayClickCount = isHovered
    ? broadcastClickCount
    : broadcastClickCountPercentage;
  return (
    <>
      <TableRow
        sx={{ fontWeight: 300 }}
        hover
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <TableCell sx={{ width: 200 }}>
          <Link
            component={RouterLink}
            href={`/app/campaign/${broadcastId}`}
            sx={(theme) => ({
              color: theme.palette.grey[900],
            })}
          >
            {broadcastName}
          </Link>
        </TableCell>

        <TableCell>
          <Label color={status.statusColor}>{status.statusText}</Label>
        </TableCell>

        <TableCell>{new Date(broadcastTime).toLocaleDateString()}</TableCell>

        <TableCell>{broadcastRecipientCount}</TableCell>

        <TableCell>{display24Hour}</TableCell>

        <TableCell>{display7Day}</TableCell>

        <TableCell>{displayClickCount}</TableCell>

        <TableCell>{broadcastAttributionRevenue.toFixed(2)}</TableCell>

        <TableCell align="right" sx={{ px: 1 }}>
          <IconButton
            color={popover.open ? "inherit" : "default"}
            onClick={popover.onOpen}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            onViewRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
          disabled={broadcastStatus !== BroadcastStatus.SCHEDULED}
        >
          <Iconify icon="uiw:edit" />
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: "error.main" }}
          disabled={
            broadcastStatus === BroadcastStatus.SENT ||
            broadcastStatus === BroadcastStatus.CANCELLED
          }
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Cancel Send
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Cancel Send"
        content="Are you sure want to cancel?"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onCancelRow();
              confirm.onFalse();
            }}
          >
            Confirm
          </Button>
        }
      />
    </>
  );
}
