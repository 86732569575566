import { useMutation, useQueryClient } from "@tanstack/react-query";

import { cancelBroadcast } from "../apiBroadcast";

const useCancelScheduledCampaign = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: cancelBroadcast,
    onSuccess: async (data, variables) => {
      return await queryClient.invalidateQueries({
        queryKey: ["broadcasts"],
      });
    },
  });
};

export default useCancelScheduledCampaign;
