import { Node } from "@xyflow/react";

export type CustomNode = Node<{
  label: string;
  title: string;
  delay?: number;
}>;

export enum ActionNodeType {
  FACEBOOK = "facebook",
  TRIGGER = "trigger",
  DELAY = "delay",
  SPLIT = "split",
}

export enum ActionEdgeType {
  FACEBOOK = "facebook",
  TRIGGER = "trigger",
  DELAY = "delay",
  SPLIT = "split",
}
