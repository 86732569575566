import { Box, Button, Container, Grid, Typography } from "@mui/material";

import { useRouter } from "../hooks";

export default function PageNotFound404() {
  const router = useRouter();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth="md">
        <Typography
          justifyContent="center"
          variant="h1"
          sx={{
            fontSize: "6rem",
            fontWeight: "bold",
            textAlign: "center",
            mb: 2,
          }}
        >
          404 Error
        </Typography>

        <Grid container spacing={0} alignItems="center">
          <Grid xs={6}>
            <Typography
              variant="h4"
              sx={{
                color: "text.secondary",
                mb: 2,
              }}
            >
              Oops! The page you’re looking for does not exist.
            </Typography>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => router.back()}
              sx={{
                p: 1,
                width: "160px",
              }}
            >
              Go Back
            </Button>
          </Grid>
          <Grid xs={6}>
            <img src="/sad_cat.png" alt="" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
