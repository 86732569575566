import { useQuery, useQueryClient } from "@tanstack/react-query";

import { getBroadcasts } from "../apiBroadcast";

const useGetBroadcasts = ({
  pageId,
  page,
  PAGE_SIZE,
  isFlow,
}: {
  pageId: string;
  page: number;
  PAGE_SIZE: number;
  isFlow: boolean;
}) => {
  const queryClient = useQueryClient();

  const {
    isLoading: broadcastListIsLoading,
    data: { data: broadcastsData } = {},
    error,
  } = useQuery({
    queryKey: ["broadcasts", pageId, page],
    queryFn: () => getBroadcasts({ pageId, page, PAGE_SIZE, isFlow }),
    retry: 1,
  });

  const { data, results: count } = broadcastsData || {};
  const pageCount = Math.ceil(count / PAGE_SIZE);

  if (page < pageCount)
    queryClient.prefetchQuery({
      queryKey: ["broadcasts", pageId, page + 1],
      queryFn: () =>
        getBroadcasts({ pageId, page: page + 1, PAGE_SIZE, isFlow }),
    });
  if (page > 1)
    queryClient.prefetchQuery({
      queryKey: ["broadcasts", pageId, page - 1],
      queryFn: () =>
        getBroadcasts({ pageId, page: page - 1, PAGE_SIZE, isFlow }),
    });

  return { broadcastListIsLoading, data, count, error };
};

export default useGetBroadcasts;
