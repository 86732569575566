import apiClient from "./client";

const getBroadcasts = async ({
  pageId,
  page,
  PAGE_SIZE,
  isFlow,
}: {
  pageId: string;
  page: number;
  PAGE_SIZE: number;
  isFlow: boolean;
}) => {
  return apiClient.get(
    `/api/broadcast/${pageId}?page=${page}&limit=${PAGE_SIZE}&sort=-broadcastTime&isFlow=${isFlow}`
  );
};

const getBroadcast = async ({
  pageId,
  broadcastId,
}: {
  pageId: string;
  broadcastId: string;
}) => {
  return apiClient.get(`/api/broadcast/${pageId}?broadcastId=${broadcastId}`);
};

const cancelBroadcast = async (broadcastId: string) => {
  const response = await apiClient.delete(`/api/broadcast/${broadcastId}`);
  return response.data;
};

const sendBroadcast = async (formData: object) => {
  const response = await apiClient.post("/api/broadcast", formData);
  return response.data;
};

const sendPreview = async (formData: object) => {
  const response = await apiClient.post("/api/message/preview", formData);
  return response.data;
};

const updateBroadcast = async ({
  broadcastId,
  formData,
}: {
  broadcastId: string;
  formData: any;
}) => {
  const response = await apiClient.put(
    `/api/broadcast/${broadcastId}`,
    formData
  );
  return response.data;
};

const updateFlow = async ({ flowId, data }: { flowId: string; data: any }) => {
  console.log("PUT REQUEST CALLED");
  const response = await apiClient.put(
    `/api/v2/broadcast/flow/${flowId}`,
    data
  );
  return response.data;
};

export {
  getBroadcasts,
  getBroadcast,
  cancelBroadcast,
  sendBroadcast,
  sendPreview,
  updateBroadcast,
  updateFlow,
};
