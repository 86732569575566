import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updatePageAttributionWindow } from "../apiPage";

const useUpdateAttributionWindow = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePageAttributionWindow,
    onSuccess: async (data, variables) => {
      return await queryClient.invalidateQueries({
        queryKey: ["page", variables.pageId],
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });
};

export default useUpdateAttributionWindow;
