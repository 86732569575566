import Box from "@mui/material/Box";
import {
  Background,
  Controls,
  MiniMap,
  ProOptions,
  ReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";

import edgeTypes from "../../components/react-flow/EdgeTypes";
import nodeTypes from "../../components/react-flow/NodeTypes";
import useAutoLayout from "../../components/react-flow/hooks/useAutoLayout";
import useSaveFlowChanges from "../../components/react-flow/hooks/useSaveFlowChanges";

const proOptions: ProOptions = { account: "paid-pro", hideAttribution: true };

const NODE_WIDTH = 350;
const STARTING_X = window.innerWidth * 0.5 - NODE_WIDTH / 2;

export default function FlowCanvas({ currentFlow }: any) {
  const { nodes, edges } = currentFlow;
  useAutoLayout();
  useSaveFlowChanges();

  return (
    <Box sx={{ width: "100%", height: "75vh" }}>
      <ReactFlow
        defaultNodes={nodes}
        defaultEdges={edges}
        proOptions={proOptions}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        minZoom={0.2}
        defaultViewport={{ x: STARTING_X, y: 150, zoom: 0.9 }}
        panOnScroll={true}
        nodesDraggable={false}
        nodesConnectable={false}
        zoomOnDoubleClick={false}
        deleteKeyCode={null}
      >
        <Controls showInteractive={false} />
        <Background color="#000000" />
        <MiniMap />
      </ReactFlow>
    </Box>
  );
}
